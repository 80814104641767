import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {HelperService, SingleSignOnService, SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, EMPTY, from, Observable, of, ReplaySubject, switchMap} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {ADMIN_ROLE, HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from '../application/data-types'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * Tells if the logged-in user can use the application
   */
  isUser: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: Observable<SpbConfiguration>
  /**
   * Publish and subscribe to the currently logged-in user here...
   */
  public currentUser$: Observable<SparbankenUser | null>

  /**
   * The access token, primarily needed for the auth interceptor,
   * Note that signal does not work due to that user self is called
   * before the value is propagated.
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  private pCurrentUser$: ReplaySubject<SparbankenUser | null> = new ReplaySubject<SparbankenUser | null>(1)

  /**
   * Publish and subscribe to the current SpbConfiguration here...
   */
  private currentConfig: SpbConfiguration = {isUser: false}

  private pConfigState$: BehaviorSubject<SpbConfiguration> = new BehaviorSubject<SpbConfiguration>({isUser: false})

  constructor(
    private ssoService: SingleSignOnService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.configState$ = this.pConfigState$.asObservable()
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }


  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }


  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge/concat
   */
  public sso(): Observable<string> {
    return this.ssoService
      .getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST _reset_ if the SSO service says we are logged out!
          // But we do not call the logout again, that is redundant.
          this.reset()
          return EMPTY
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payload = HelperService.GetTokenPayload(token)
    if (payload) {
      this.accessToken$.next(token)
      this.currentConfig = {
        token,
        isUser: payload.roles.indexOf(ADMIN_ROLE) !== -1
      }
      this.helperService.getCurrentUser(environment.authServiceUrl).subscribe({
        next: (user: SparbankenUser) => this.pCurrentUser$.next(user)
      })
      this.pConfigState$.next(this.currentConfig)
      return from(this.router.navigate([HOME_ROUTE_PATH]))
    }
    /**
     * If we are called with invalid tokens we reset all log in data
     * we do not explicitly LOG OUT!
     */
    this.reset()
    return of(true)
  }


  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    // Reset all values and navigates to login
    this.reset()
  }

  /**
   * Reset all admin values, and go to login. Do
   * not explicitly log out.
   */
  public reset(): void {
    this.accessToken$.next(null)
    this.currentConfig = {isUser: false}
    this.pCurrentUser$.next({name: '', roles: []} as any) // Lame reset
    this.pConfigState$.next(this.currentConfig)
    this.router.navigate([LOGIN_ROUTE_PATH]).then()
  }
}
